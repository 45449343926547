<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand @click="$router.push({ name: 'home' }).catch(err => { err })" style="font-weight: 900;">
        <img src="../assets/logo-no-background.png" alt="Kitten" style="height:40px">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav v-model="visible">
        <b-navbar-nav>
          <b-nav-item v-for="item in categories" :key="item.id" @click="navigateTo(item)">
            {{ item.name }}
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form @submit="onSearch">
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search" v-model="searchKeyWords"
              @submit="onSearch"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" @click="onSearch">Search</b-button>
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import gameApi from '../api/gameApi.js';

export default {
  name: 'BYNavBar',
  props: {
    msg: String
  },
  data() {
    return {
      categories: [],
      searchKeyWords: '',
      visible: false
    }
  },
  mounted() {
    console.log('BYNavBar mounted');

    gameApi.getCategories().then((response) => {
      console.log('response', response);
      if (response.status === 200 || response.data.code !== 0) {
        this.categories = response.data.data.items;
        console.log('categories: ', this.categories);
      } else {
        console.log('request gameApi.getCategories error', response);
      }
    });
  },
  methods: {
    navigateTo(item) {
      console.log('navigateTo', item);
      this.$router.push({ name: 'category', params: { categoryname: item.name, categoryid: item.id } }).catch(err => { err });
    },
    onSearch(event) {
      event.preventDefault();
      if (this.searchKeyWords === '') {
        return;
      }
      // navigate to search page with search keyword
      this.$router.push({ name: 'search', params: { searchquery: this.searchKeyWords } }).catch(err => { err });
      this.searchKeyWords = '';
      this.visible = false;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.8);
}
</style>
